'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['ActiveMode','$log', '$routeParams'];

function controller (ActiveMode, $log, $routeParams) {
  var ctrl = this;
  var reportComponents = {
    'spire-class-level-progress' : { component: 'xp-spire-class-level-progress'},
    'spire-school-level-progress' : { component: 'xp-spire-admin-level-progress', drilldown: 'xp-spire-class-level-progress'},
    'spire-district-level-progress' : { component: 'xp-spire-admin-level-progress', drilldown: 'xp-spire-class-level-progress'},
    'spire-subscription-level-progress' : { component: 'xp-spire-admin-level-progress', drilldown: 'xp-spire-class-level-progress'},
    'spire-student-summary' : { component: 'xp-spire-student-summary'},

    'curriculum-student-progress' : { component: 'student-progress', drilldown: 'student-summary'},

    'curriculum-experience-summary' : { component: 'experience-summary'},

    'district-experience-summary' : { component: 'experience-summary'},
    'school-experience-summary' : { component: 'experience-summary'},

    'district-curriculum-progress' : { component: 'district-curriculum-progress'},

    'school-curriculum-progress' : { component: 'district-curriculum-progress'},

    'school-teks-summary': { component: 'school-district-teks-summary', drilldown: 'curriculum-teks-details', drilldown2: 'curriculum-teks-summary'},
    'school-teks-summary-no-type': { component: 'school-district-teks-summary', drilldown: 'curriculum-teks-details', drilldown2: 'curriculum-teks-summary'},

    'district-teks-summary': { component: 'school-district-teks-summary', drilldown: 'curriculum-teks-details', drilldown2: 'curriculum-teks-summary'},
    'district-teks-summary-no-type': { component: 'school-district-teks-summary', drilldown: 'curriculum-teks-details', drilldown2: 'curriculum-teks-summary'},

    'curriculum-teks-summary': { component: 'curriculum-teks-summary'},
    'curriculum-teks-summary-no-type': { component: 'curriculum-teks-summary'},

    'curriculum-teks-tia-summary': { component: 'curriculum-teks-tia-summary'},
    'school-teks-tia-summary': { component: 'school-teks-tia-summary', drilldown: 'curriculum-teks-tia-summary'},
    'district-teks-tia-summary': { component: 'district-teks-tia-summary', drilldown: 'curriculum-teks-tia-summary'},

    'curriculum-teks-details': { component: 'curriculum-teks-details'},
    'curriculum-teks-details-no-type': { component: 'curriculum-teks-details'},

    'curriculum-student-learning': { component: 'standard-student-learning'},
    'school-student-learning': { component: 'standard-student-learning'},
    'district-student-learning': { component: 'standard-student-learning'},

    'curriculum-student-learning-multi-top': { component: 'standard-student-learning'},
    'school-student-learning-multi-top': { component: 'standard-student-learning'},
    'district-student-learning-multi-top': { component: 'standard-student-learning'},

    'district-analyzer': { component: 'analyzer-report'},
    'school-analyzer': { component: 'analyzer-report'}
  };

  function getReportComponent(key) {
    var componentName = "non-existent-report";

    // Lookup the mapping from report to component
    var repComponent = reportComponents[key];
    if (repComponent) {

      // Check for a drill down parameter on the URL
      var drilldown = $routeParams.drilldown;
      var drilldown2 = $routeParams.drilldown2;

      // if drill down is defined as part of the query for a report then navigate to the drill down report
      if (drilldown && repComponent.drilldown) {
        componentName = repComponent.drilldown;
      } else if (drilldown2 && repComponent.drilldown2){
        componentName = repComponent.drilldown2;
      }
      else {
        componentName = repComponent.component;
      }
    }
    return componentName;
  }

  // Get the Current report structure
  ActiveMode.getReport(ActiveMode.currentReportId()).then(function(reportDef) {
    ctrl.title = reportDef.name;
    ctrl.component = getReportComponent(reportDef.report_kind);
  })
  .catch(function(error) {
    $log.error("error in getting report:",error);
    ctrl.title = "Invalid Report";
    ctrl.component = "non-existent-report";
  });

}

module.component('xpReport', {
  template: require('./report.jade'),
  controller: controller,
});

})();
