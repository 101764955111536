'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('xpWhiteboard', [
   function() {

     function link(scope, element, attrs)
     {
     }

     return {
       replace: true,
       restrict: 'E',
       transclude: true,
       template: require('./projectorView.jade'),
       link: link,
       scope: {
         projectorView: '=',
         controllerView: '='
       }
     };
   }]);
})();
