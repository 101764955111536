'use strict';

angular.module('client.services').factory('WhiteboardService', ['$resource', '$sessionStorage', '$q', '$rootScope', 'api_server',
  function ($resource, $sessionStorage, $q, $rootScope, api_server) {

    var whiteboardId = generateWhiteboardId();
    var whiteboardNavSync = false;
    var showStudentResults = true;
    var showStudentResponses = true;
    var showResponsesToggleState = false;
    var lastExperienceId = 0;

    function generateWhiteboardId() {
      var id = $sessionStorage['whiteboard_id'];
      if (id) {
        return id;
      }
      id = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
      $sessionStorage['whiteboard_id'] = id;
      return id;
    }

    var whiteboardProjectorResource = $resource(api_server + '/experiences/:id/whiteboard/projector', {presentation_state: '@presentation_state'},
      {
        getState: {method: 'GET', params: {id: '@experienceId'}},
        saveState: {method: 'POST', params: {id: '@experienceId'}},
        deleteState: {method: 'DELETE', params: {id: '@experienceId'}}
      });

    function getWhiteboardState(experienceId) {
      return whiteboardProjectorResource.getState({id: experienceId}).$promise;
    }

    function saveWhiteboardProjectorState(experienceId) {
      lastExperienceId = experienceId;
      return whiteboardProjectorResource.saveState({id: experienceId}, {presentation_state: whiteboardId}).$promise;
    }

    function deleteWhiteboardProjectorState(experienceId) {
      lastExperienceId = 0;
      return whiteboardProjectorResource.deleteState({id: experienceId}).$promise;
    }

    var whiteboardControllerResource = $resource(api_server + '/experiences/:id/whiteboard/controller', {controller_state: '@controller_state'},
      {
        saveState: {method: 'POST', params: {id: '@experienceId'}},
        deleteState: {method: 'DELETE', params: {id: '@experienceId'}}
      });

    function saveWhiteboardControllerState(experienceId) {
      return whiteboardControllerResource.saveState({id: experienceId}, {controller_state: whiteboardId}).$promise;
    }

    function deleteWhiteboardControllerState(experienceId) {
      return whiteboardControllerResource.deleteState({id: experienceId}).$promise;
    }

    var whiteboardOptionsResource = $resource(api_server + '/experiences/:id/whiteboard/projector/options', {
        show_student_responses: '@show_student_responses',
        navigation_sync: '@navigation_sync'
      },
      {
        updateOptions: {method: 'POST', params: {id: '@experienceId'}}
      });

    function updateProjectorOptions(experienceId, state) {
      let options = {};
      if (state.hasOwnProperty("show_student_responses")) {
        options.show_student_responses = state.show_student_responses;
      }
      if (state.hasOwnProperty("navigation_sync")) {
        options.navigation_sync = state.navigation_sync;
      }
      return whiteboardOptionsResource.updateOptions({id: experienceId}, options).$promise;
    }

    var whiteboardScrollResource = $resource(api_server + '/experiences/:id/whiteboard/projector/scroll', {
        scroll: '@scroll',
        scene: '@scene'
      },
      {
        scrollTo: {method: 'POST', params: {id: '@experienceId'}}
      });

    function scrollProjector(experienceId, scrollPos) {
      return whiteboardScrollResource.scrollTo({id: experienceId}, {scroll: scrollPos}).$promise;
    }

    function setProjectorScene(experienceId, sceneId) {
      return whiteboardScrollResource.scrollTo({id: experienceId}, {scene: sceneId}).$promise;
    }

    function getId() {
      return whiteboardId;
    }

    function setShowStudentResponses(enabled) {
      showStudentResponses = enabled;
      return showStudentResponses;
    }

    function setWhiteboardSync(enabled) {
      whiteboardNavSync = enabled;
      return whiteboardNavSync;
    }

    function getWhiteboardSync() {
      return whiteboardNavSync;
    }

    var whiteboardResponseResource = $resource(api_server + '/experiences/:id/whiteboard/projector/responses', {show: '@show'},
      {
        show: {method: 'POST', params: {id: '@experienceId'}}
      });

    function toggleShowStudentResponses(experienceId, show) {
      showResponsesToggleState = show;
      if (experienceId) {
        whiteboardResponseResource.show({id: experienceId}, {show: showResponsesToggleState}).$promise;
      }
    }

    function getShowStudentResponses() {
      return showStudentResponses;
    }

    function getShowResponsesToggleState() {
      return showResponsesToggleState;
    }

    function clearProjector() {
      if (lastExperienceId) {
        return deleteWhiteboardProjectorState(lastExperienceId);
      } else {
        return $q.when();
      }
    }

    var whiteboardCleanupResource = $resource(api_server + '/whiteboard/projector/cleanup', {state: '@state'},
      {
        deleteState: {method: 'DELETE'}
      });

    function cleanup() {
      whiteboardCleanupResource.deleteState({}, {state: whiteboardId}).$promise;
    }

    $rootScope.$on('onBeforeUnload', function (e, args) {
      cleanup();
    });


    return {
      getWhiteboardState: getWhiteboardState,
      saveWhiteboardProjectorState: saveWhiteboardProjectorState,
      deleteWhiteboardProjectorState: deleteWhiteboardProjectorState,
      saveWhiteboardControllerState: saveWhiteboardControllerState,
      deleteWhiteboardControllerState: deleteWhiteboardControllerState,
      updateProjectorOptions: updateProjectorOptions,
      scrollProjector: scrollProjector,
      setProjectorScene: setProjectorScene,
      getId: getId,
      setShowStudentResponses: setShowStudentResponses,
      getShowStudentResponses: getShowStudentResponses,
      setWhiteboardSync: setWhiteboardSync,
      getWhiteboardSync: getWhiteboardSync,
      toggleShowStudentResponses: toggleShowStudentResponses,
      getShowResponsesToggleState: getShowResponsesToggleState,
      clearProjector: clearProjector,
      cleanup: cleanup
    };
  }]);
