module.exports = {
  port : 7777,
  newrelic: false,
  logger: {
    level: 'debug',
    config_file: ''
  },
  realtime: {
    url: process.env.REALTIME_URL
  },
  cookies : {
    token_cookie_name: process.env.TOKEN_COOKIE_NAME ,
    session_details_cookie_name: process.env.SESSION_DETAILS_COOKIE_NAME,
    domain: '.exploros.com',
    old_cookie_name: 'exploros_token'
  }
};
