'use strict';
(function () {

  var module = angular.module('client.services');
  module.factory('logoutService',
    ['$q', '$log', 'LoginFactory', 'User', 'InvalidateSessionFactory',
      'userPermissions', 'userClassPermissions', 'userCurriculumPermissions', 'userSubscriptionPermissions',
      'UserCourses', 'UserDefaultInfo', 'UserLastActive', 'UserManagedSubscriptions', 'xpMetrics', 'tourService',
      'WhiteboardService',
      function ($q, $log, LoginFactory, User, InvalidateSessionFactory,
                userPermissions, userClassPermissions, userCurriculumPermissions, userSubscriptionPermissions,
                UserCourses, UserDefaultInfo, UserLastActive, UserManagedSubscriptions, xpMetrics, tourService,
                WhiteboardService) {

        function clearUserState() {
          xpMetrics.meterLogout();
          User.logout();
          UserLastActive.set();
          userPermissions.clearPermissions();
          userClassPermissions.clearPermissions();
          userCurriculumPermissions.clearPermissions();
          userSubscriptionPermissions.clearPermissions();
          UserManagedSubscriptions.resetSubscriptions();
          UserCourses.clearCachedCourses();
          UserDefaultInfo.removeUserInfo();
          tourService.reset();
          WhiteboardService.cleanup();
          // continue if we soon will get userId back without reload/re-init
          xpMetrics.continueMetering();
          return $q.when([]);
        }

        function clearTimeoutState() {
          return WhiteboardService.clearProjector();
        }

        return {
          logout: function logoutUser() {
            $log.info('logoutService:logout()');

            // block before we clean up user state
            xpMetrics.stopMetering();
            return LoginFactory.logout().$promise
              .then(clearUserState)
              .catch(function (error) {
                $log.info('an error occurred while logging out: ' + error);
              });
          },
          logOutByTimeout: function logOutByTimeout() {
            return clearTimeoutState().then(function() {
              return InvalidateSessionFactory.invalidate({token: User.getSessionToken()}).$promise;
            })
          },
          stopMetering: xpMetrics.stopMetering,
          continueMetering: xpMetrics.continueMetering,
          invalidate: function invalidate() {
            $log.info('logoutService:invalidate()');
            xpMetrics.stopMetering();
            return InvalidateSessionFactory.invalidate({token: User.getSessionToken()}).$promise
              .then(clearUserState)
              .catch(function (error) {
                $log.info('an error occurred while invalidating session: ' + error);
              });
          }
        };
      }]);

  module.factory('loginService',
    ['$q', '$log', '$location', 'LoginFactory', 'token_cookie_name', '$cookies', '$sessionStorage',
      'UserManagedSubscriptions', 'UserCourses', 'PermissionConsts',
      'userPermissions', 'User', 'WebSocket', '$localStorage',
      'ActiveMode', 'UserInfoDefaultFactory', 'logoutService', 'UserLastActive', 'xpMetrics',
      'UserTrials', '$window', 'InvalidateSessionFactory',
      function ($q, $log, $location, LoginFactory, token_cookie_name, $cookies, $sessionStorage,
                UserManagedSubscriptions, UserCourses, PermissionConsts,
                userPermissions, User, WebSocket, $localStorage,
                ActiveMode, UserInfoDefaultFactory, logoutService, UserLastActive, xpMetrics,
                UserTrials, $window, InvalidateSessionFactory) {

        function districtAdminLogin() {
          return ($location.path() === '/experience' &&
            userPermissions.hasPermission(PermissionConsts.ui_show_admin_interface));
        }

        // We may want specific paths to be opened in a second tab.  The problem with multiple tabs is that the realtime
        // system really works for a single browser window.  As long as the new tab doesn't require realtime events then
        // it can be opened in a new tab.  However, it must be added to this list so the new tab doesn't re-initialize
        // the realtime system for the new tab.
        const duplicateTabPaths = ['/helpVideos'];

        function validNewTab(oldToken) {
          if (oldToken && duplicateTabPaths.includes($location.path())) {
            return LoginFactory.heartbeat().$promise.then(function (hb) {
              return hb.token === oldToken;
            });
          } else {
            return Promise.resolve(false);
          }
        }

        function initLoginBySessionOrToken() {
          if ($location.path() === '/login') {
            return null; // we don't ewant to login before a button pressed.
          }

          $log.info('attempting login');

          var tokenStr = null;
          var sessionToken = null;
          var oldSessionToken = null;
          var paramToken = $location.search().token;
          var api_uid = $location.search().api_uid;

          return Promise.resolve().then(function () {
            if (
              $localStorage[token_cookie_name] && paramToken &&
              ($localStorage[token_cookie_name] !== paramToken)
            ) {
              $log.info("Old token vs URL token: %s | %s", $localStorage[token_cookie_name], paramToken);
              $localStorage[token_cookie_name] = null;
            }
          }).then(function () {
            if ($localStorage[token_cookie_name]) {
              $log.debug("Taking token from localStorage: ", token_cookie_name);
              tokenStr = $localStorage[token_cookie_name];
              oldSessionToken = tokenStr;
            } else if (paramToken) {
              $log.info("Taking token from URL: ", paramToken);
              tokenStr = paramToken;
            }

            if (api_uid) {
              $cookies.put('api_uid', api_uid);
            }

            if ($cookies.get('signup') === 'true') {
              $log.info('singup flow detected');
              $cookies.remove('signup', {path: "/"});
              $cookies.remove('signup', {path: "/", domain: '.exploros.com'});
              $cookies.remove('signup');
            } else if (!tokenStr || tokenStr.length < 10) {
              $log.warn('no token or session detected');
              return null;
            }

            return LoginFactory.validate({token: tokenStr}).$promise.then(function (session) {
              $log.info('LoginFactory.validated session:', session.token);
              $location.search('token', null);

              $localStorage[token_cookie_name] = session.token;
              sessionToken = session.token;
              var user = session.user || {};

              if (!user.id) {
                $log.error('no user id in session data:', session);
                return $q.reject('no user id in session data');
              }
              if (!sessionToken) {
                $log.error('no token in session:', session);
                return $q.reject('no token in session');
              }
              return UserInfoDefaultFactory.get({id: user.id}).$promise;
            }).then(function (user) {
              $log.info("logged user in by token " + tokenStr);

              if (!user || !user.id) {
                $log.error("User login failure for token ", tokenStr);
                return $q.reject('User login failure for token');
              }
              if (!user || !user.id) {
                $log.error("User login failure for token ", tokenStr);
                return $q.reject('User login failure for token');
              }
              return {
                user: user,
                token: sessionToken,
                oldToken: oldSessionToken
              };
            }).then(loadTheSystem)
              .then(function (loginData) {
                $log.info("Successfully init: ", loginData);

                if ($location.path() === '/login' || districtAdminLogin()) {
                  $log.info("navigateToDefaultView: ", loginData.token);
                  ActiveMode.navigateToDefaultView();
                }

                return loginData;
              });
          }).catch(function (data) {
            $log.error('Error retrieving user information from LoginFactory:', data);
            $location.search('token', null);

            User.logout();
            if (User.getSessionToken()) {
              InvalidateSessionFactory.invalidate({token: User.getSessionToken()})
              .then(function() {
              }).catch(function(err) {
                $log.warn("invalidate error: ", err);
              });
            }

            if (data && data.error) {
              $log.error(data.error);
            }

            if ($location.path() !== '/login') {
              $location.path('/login');
            }
          });
        }

        function loadTheSystem(loginData) {
          return $q.when().then(function () {
            User.setCurrentUser(loginData.user);
            User.setSessionToken(loginData.token);
            UserLastActive.set();
            return loginData;
          }).then(function () {
            return validNewTab(loginData.oldToken).then(function (isLoggedIn) {
              if (!isLoggedIn) {
                return WebSocket.defineWebSocket(loginData.token);
              } else {
                return Promise.resolve();
              }
            });
          }).then(function () {
            return userPermissions.resetPermissions();
          }).then(function () {
            return UserManagedSubscriptions.reloadSubscriptions();
          }).then(function () {
            return UserCourses.updateCachedCourses();
          }).then(function () {
            return UserTrials.getUserTrials();
          }).then(function () {
            User.markAsLoggedIn();
            xpMetrics.meterLogin();
            return loginData;
          });
        }

        return {
          login: function processLogin(username, password) {
            return LoginFactory.login({username: username, password: password}).$promise.then(function (userData) {
              if (userData.error) {
                return userData;
              }
              if (!userData.user) {
                $log.error('no user data on login:', userData);
                return $q.reject('no user data on login:');
              }
              return loadTheSystem(userData).then(function (loginData) {
                ActiveMode.navigateToDefaultView();
                return loginData;
              });
            });
          },
          initLoginBySessionOrToken: initLoginBySessionOrToken,
          logout: function logoutUser() {
            $log.warn("loginService.logout()");

            return logoutService.logout().then(function () {
              WebSocket.disconnect(WebSocket.CLOSE_REASONS.LOGOUT);
              $location.path('/login');
            });
          }
        };
      }]);
})();
