'use strict';
(function () {
  var elementTimeOnScreenCycleMS = 1000;
  var flushElementsCycleMS = 30000;
  var experienceTimeOnScreenCycleMS = 10000;
  var presenceCycleMS = 30000;

  var module = angular.module('client.services');

  var placeholderFn = function dummy() {
  };

  var elementTimeOnScreenContainer = [];

  module.factory('xpMetrics',
    ['$q', '$interval', 'User', '$location', '$log',
      function ($q, $interval, User, $location, $log) {

        if (!window.Primus) {
          $log.warn('Metrics service is switched off');
          return {
            startMeteringCycle: placeholderFn,
            meterLogin: placeholderFn,
            meterLogout: placeholderFn,
            stopMetering: placeholderFn,
            continueMetering: placeholderFn
          };
        }

        var metricsReporter = new window.Primus({url: process.env.PRIMUS_PATH});

        var timeOnScreenInterval;
        var flushElementsCycleInterval;
        var timeOnExprienceInterval;
        var presenceInterval;
        var allowMetering = true;

        function stopMetering() {
          allowMetering  = false;
        }

        function continueMetering() {
          allowMetering = true;
        }

        function isProhibitToMeter() {
          return !(allowMetering && User.getId() && User.hasToken() );
        }

        // need to recreate connection so we pass the new cookie
        function meterLogin() {
          if(metricsReporter && metricsReporter.end){
            metricsReporter.end();
          }
          metricsReporter = new window.Primus({url: process.env.PRIMUS_PATH});
          metricsReporter.emit('login', User.getId());
        }

        function meterLogout() {
          metricsReporter.emit('logout', null);
        }

        function meterPresence() {
          if (isProhibitToMeter()) {
            return false;
          }
          metricsReporter.emit('presence', {
            user_id: User.getId(),
            url: $location.absUrl()
          });
        }

        function isElementVisible(xpElement) {
          var el = xpElement.querySelector('[ng-controller]');
          if (!el) {
            return false;
          }
          var rect = el.getBoundingClientRect();

          return !(rect.bottom < 0 || rect.top > (window.innerHeight || document.documentElement.clientHeight));
        }

        function formatTrackedElementData(elementMetricData) {
          return {
            period: 1,
            experience_id: elementMetricData.experience_id,
            scene_id: elementMetricData.scene_id,
            cluster_id: elementMetricData.cluster_id,
            element_id: elementMetricData.element_id,
            element_template_id: elementMetricData.element_template_id,
            element_type: elementMetricData.element_type,
            class_id: elementMetricData.class_id,
            small_group_id: elementMetricData.small_group_id,
            user_id: User.getId()
          };
        }

        function formatTrackedExperienceData(xpMetric) {
          return {
            user_id: User.getId(),
            experience_id: xpMetric.experience_id,
            experience_template_id: xpMetric.experience_template_id,
            experience_status: xpMetric.experience_status,
            class_id: xpMetric.class_id,
          };
        }

        function elementTimeOnScreenCheck() {
          if (isProhibitToMeter()) {
            return false;
          }

          var xpElementsInDom = Array.from(document.querySelectorAll('xp-widget'));
          var visibleElements = xpElementsInDom.filter(isElementVisible);
          var trackedElements = visibleElements.map(function (el) {
            return el.getAttribute('data-xp-bi-tracking-element');
          }).map(JSON.parse);

          trackedElements
            .filter(function(element){
              return element;
            })
            .filter(function (element) {
              return (element.status || '').toUpperCase() === 'ACTIVE' || (element.status || '').toUpperCase() === 'PENDING';
            })
            .forEach(function (element) {
              elementTimeOnScreenContainer.push(formatTrackedElementData(element));
            });
        }

        function getKeyOfElementRecord(rec) {
          return rec.experience_id + ':' + rec.user_id + ':' + rec.small_group_id + ':' + rec.element_id + ':' + rec.element_template_id;
        }

        function flushElementsTimeOnScreen() {
          var tempContainer = [];
          var lengthAtTheMoment = elementTimeOnScreenContainer.length;
          for(var i=0; i < lengthAtTheMoment ; i++){
            tempContainer.push(elementTimeOnScreenContainer.shift());
          }
          var elementsByKey = {};
          tempContainer.forEach(function (rec) {
            var key = getKeyOfElementRecord(rec);
            if(!elementsByKey[key]){
              elementsByKey[key] = rec;
              return;
            }
            elementsByKey[key].period = elementsByKey[key].period + rec.period;
          });

          var records = Object.keys(elementsByKey).map(function (key) {
            return elementsByKey[key];
          });
          if(!records.length){ return; }

          metricsReporter.emit('element-time-on-screen-collected', records);

        }

        function experienceTimeCheck() {
          if (isProhibitToMeter()) {
            return false;
          }

          var experienceMain = document.querySelector('#main_activity_view');
          if (!experienceMain) {
            return false;
          }
          var trackingBlob = experienceMain.getAttribute('data-xp-bi-tracking-experience');
          if (!trackingBlob) {
            return false;
          }
          var trackingData = JSON.parse(trackingBlob);
          if (!trackingData) {
            return false;
          }
          if (trackingData.user_id !== User.getId()) {
            return false;
          }
          var metridData = formatTrackedExperienceData(trackingData);
          metricsReporter.emit('experience-time-on-screen', metridData);
        }


        function startMeteringCycle() {
          if (timeOnScreenInterval) {
            $interval.cancel(timeOnScreenInterval);
          }
          timeOnScreenInterval = $interval(elementTimeOnScreenCheck, elementTimeOnScreenCycleMS);

          if (flushElementsCycleInterval) {
            $interval.cancel(flushElementsCycleInterval);
          }
          flushElementsCycleInterval = $interval(flushElementsTimeOnScreen, flushElementsCycleMS);

          if (presenceInterval) {
            $interval.cancel(presenceInterval);
          }
          presenceInterval = $interval(meterPresence, presenceCycleMS);

          if (timeOnExprienceInterval) {
            $interval.cancel(timeOnExprienceInterval);
          }

          timeOnExprienceInterval = $interval(experienceTimeCheck, experienceTimeOnScreenCycleMS);
        }

        return {
          startMeteringCycle: startMeteringCycle,
          meterLogin: meterLogin,
          meterLogout: meterLogout,
          stopMetering: stopMetering,
          continueMetering: continueMetering
        }
      }]);
})();


