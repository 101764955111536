'use strict';
(function () {
angular.module('xp-element-hot-text', ['angularWidget', 'client.services', 'client.directives', 'ngAnimate', 'mgcrea.ngStrap', 'ngSanitize'])
  .controller('clientHotTextElementCtrl',
    ['$scope', '$rootScope', '$log','widgetConfig', '$http', 'ElementsRestService', 'ElementUtilities',
    'JSONStringUtility', '$timeout', 'SHARE_MODE', 'GATE_MODE', 'ElementsErrorService',
    'IconOverlays', 'RespondentType', '$sce', 'ModalService',  '$q', 'ActiveExperience', 'CorrectResponseId',
    function ($scope, $rootScope, $log, widgetConfig, $http, ElementsRestService, ElementUtilities,
      JSONStringUtility, $timeout, SHARE_MODE, GATE_MODE, ElementsErrorService,
      IconOverlays, RespondentType, $sce, ModalService, $q, ActiveExperience, CorrectResponseId) {

      var QUESTION_PARSE_MODE = Object.freeze({QUESTION: 'question', ANSWER: 'answer'});

      $scope.instructions = {};
      $scope.editing = false;
      $scope.responses = [];
      $scope.respondents = [];
      $scope.selectedRespondent = null;
      $scope.isTeacher = false;
      $scope.isAssessed = false;
      $scope.initialized = false;
      $scope.allowEditing = false;
      $scope.allResponses = [];
      $scope.answered = false;
      $scope.assessmentStatus = false;
      $scope.status = [];
      $scope.multiSelect = false;
      $scope.maxSelect = 1;

      var context = null;
      var element = null;
      var share = null;

      $scope.gateMode = GATE_MODE.XPGateModeGated;
      $scope.SHARE_MODE = SHARE_MODE;
      $scope.share = SHARE_MODE.TEACHER;

      // The element is the block of data provided by the source xml
      $scope.options = widgetConfig.getOptions($scope);

      function parseQuestion(question) {
        var result = { segments: [], locations: 0 };

        // Search through question for each option marker
        var source = question;

        var count = 0;
        var answerIndex = 0;
        var startIndex = 0;
        var mode = QUESTION_PARSE_MODE.QUESTION;
        var index = source.indexOf("___");
        while (index >= 0) {
          // if this is the question then left of text is part of the question
          if (mode === QUESTION_PARSE_MODE.QUESTION) {
            result.segments.push({ text: $sce.trustAsHtml(source.slice(0, index)), type: "text", position: startIndex + count } );
            count = count + 1;
          } else {
            // Grab the portion of the text that is the answer
            var inlineAnswer = source.slice(0, index);

            // A star at the end of the text indicates the correct answer
            var correct = false;
            if (inlineAnswer.length && inlineAnswer[inlineAnswer.length-1] == '*') {
              inlineAnswer = inlineAnswer.substring(0, inlineAnswer.length-1);
              correct = true;
            }

            // Add the hot text to the results
            result.segments.push({id: answerIndex, text: $sce.trustAsHtml(inlineAnswer), type: "hottext", position: startIndex + count - 1, correct: correct });
            answerIndex = answerIndex + 1;

            // increase location count
            result.locations = result.locations + 1;
          }

          // Toggle the current mode
          mode = mode === QUESTION_PARSE_MODE.QUESTION ? QUESTION_PARSE_MODE.ANSWER : QUESTION_PARSE_MODE.QUESTION;

          // Strip the characters off the front of the string
          source = source.substr(index + 3);

          // Find the next block
          index = source.indexOf("___");
        }

        // Push remaining text onto the question
        result.segments.push({ text: $sce.trustAsHtml(source), type: "text", position: startIndex + count } );

        return result;
      }

      var parseElement = function() {
        if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes)
          return;

        $scope.options.element.config.attributes.forEach(function(attribute) {
          switch (attribute.name)
          {
          case "text" :
            $scope.instructions.question = $sce.trustAsHtml(attribute.value);
          break;
          case "image_url" :
            $scope.instructions.imageUrl = ElementUtilities.getElementURL($scope.options.element, $scope.options.context.experienceId, attribute.value);
            break;
          case "assessed" :
              $scope.isAssessed = attribute.value === "true" || attribute.value === true;
            break;
          case "multiple_allowed" :
            $scope.multiSelect = attribute.value === "true" || attribute.value === true;
            break;
          case "max_selections" :
            $scope.maxSelect = parseInt(attribute.value, 10) || 0;
            break;
          case "share" :
            $scope.share = attribute.value;
          break;
          case "hilited" :
            $scope.hilite = attribute.value === "true" || attribute.value === true;
          break;
          case "question" :
            $scope.question = parseQuestion(attribute.value);
          break;
          }
        });

        $timeout(function() {
          // Notify the widget that were are done loading the data
          widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
        });
      };

      var unregisterOptionsWatch = $scope.$watch('options', onOptionsSet, true);

      function onOptionsSet() {
        if ($scope.options.context) {
          if (!$scope.initialized) {
            context = $scope.options.context;
            element = $scope.options.element;
            parseElement();
            $scope.isTeacher = context.userIsTeacher();
            if (ActiveExperience.currentExperience() && !ActiveExperience.currentExperience().hideStudentResponses) {
              $scope.selectRespondent($scope.isUsingSmallGroups() ? context.groupId : context.userId);
            }
            var service = $scope.options.elementRealtimeService;
            var EVENTS = service.EVENTS;

            if ($scope.isTeacher || $scope.isUsingSmallGroups()) {
              service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
              $scope.$on('$destroy', function(){
                service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
              });
            }

            loadAnswers(true);
            $scope.initialized = true;
          }
        }
      }

      function stateChangedNotificationHandler(e) {
        var state = e.detail;
        if (state.record.element_id != $scope.options.element.id){
          return;
        }
        if (state.record.user_id == $scope.options.context.userId){
          return;
        }

        $log.debug ("Received hot spot state update: " + JSON.stringify(state));

        updateAnswer(state.record);
        updateRespondents(state.record);
      }

      $scope.hasResponses = function() {
        return $scope.responses.length > 0;
      };

      $scope.getUserGroup = function(userId) {
        return context && context.getUserGroup(userId);
      };

      $scope.canEditForRespondent = function(respondentId) {
        if (!$scope.options.context)
          return false;

        if ($scope.options.context.isPreview) {
          return false;
        }

        return ($scope.isTeacher ||
                (!$scope.isTeacher && $scope.responses.length > 0 && !$scope.editing)) &&
                !$scope.options.context.getViewingInactiveExperience() &&
                !$scope.options.quiz;
      };

      $scope.isUsingSmallGroups = function() {
        return SHARE_MODE.isUsingSmallGroups($scope.share);
      };

      $scope.selectRespondent = function(respondentId) {
        // Set the respondents data
        $scope.selectedRespondent = respondentId;

        // This populates the UI with the selected data
        selectRespondentData(respondentId);

        // tell UI this respondent has data
        respondentHasData(respondentId);
      };

      function respondentHasData(respondentId) {
        $scope.allowEditing = false;

        // Teacher cannot edit
        if (!$scope.isTeacher) {
          // if this is NOT a past experience
          if (!$scope.options.context.getViewingInactiveExperience()) {
            // Loop through data and see if this user has any data
            $scope.allResponses.forEach(function(response) {
              // if this response belongs to this user or the group this user is in
              if (response.respondentId === respondentId)
                $scope.allowEditing = true;
            });
          }
        }
      }

      function selectRespondentData(respondentId) {
        var found = false;
        $scope.responses = [];

        // Select this respondents data into the active set
        $scope.allResponses.forEach(function(response){
          if (response.respondentId == respondentId) {
            // We found responses for this user so reset the UI to reflect their values
            $scope.responses = response.response;
            updateChoiceStates();
            found = true;
          }
        });

        // if the selected user does not have responses then clear the values
        if (!found) {
          if (respondentId) {
            updateChoiceStates();
          } else {
            $scope.status = [];
          }
        }

        $scope.editing = false;
      }

      $scope.onRetry = function() {
        $scope.requestEdit($scope.selectedRespondent);
      };

      function displayStudentFacingFeedback() {
        // if this is a student they are not editing and they are viewing their own data
        if (($scope.options.context.getStudentFacingFeedback() || $scope.options.context.getViewingInactiveExperience()) &&
            !$scope.isTeacher && !$scope.editing && $scope.isAssessed && !$scope.options.quiz) {

          // Determine the current user/current group id
          var currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;

          // Make sure the student has data
          var studentResponses = $scope.allResponses.filter(function(response) { return response.respondentId == currentRespondentId; });

          // if any results were found return success
          return studentResponses && studentResponses.length;
        }
        return false;
      }

      function updateChoiceStates() {
        // if this is a teacher then check all the responses to see if they are correct
        if ($scope.isTeacher || displayStudentFacingFeedback()) {
          // Clear the current status markers
          $scope.status = [];

          // Loop over the responses and mark each
          for (var index = 0; index < $scope.question.segments.length; ++index) {
            // See if the response is correct
            if ($scope.question.segments[index].correct && ((CorrectResponseId.CORRECTANSWERS === $scope.selectedRespondent && $scope.isTeacher) ||
                    ($scope.responses.includes($scope.question.segments[index].id)))) {
              $scope.status[$scope.question.segments[index].id] = 1;
            } else if (!$scope.question.segments[index].correct && (($scope.options.context.userId === $scope.selectedRespondent && $scope.isTeacher && $scope.selectedRespondent !== $scope.options.context.clazz.teacher.uid) ||
                    ($scope.responses.includes($scope.question.segments[index].id)))) {
              $scope.status[$scope.question.segments[index].id] = -1;
            }
          }
        }
      }

      function updateAnswer(answer) {
        // Exception case for no responses from server
        if (!answer.user_data) {
          return;
        }

        var respondentId = $scope.isUsingSmallGroups() ?  answer.small_gid : answer.user_id;

        // See if this user has a response already
        var found = false;
        $scope.allResponses.forEach(function(response) {
          // if the respondent id matches then just update the responses
          if (response.respondentId === respondentId) {
            response.response = answer.user_data;
            response.correct = checkResponses(answer.user_data);
            found = true;
          }
        });

        // Insert this into the full set of responses
        if (!found) {
          // Check to see if this user correctly answered
          var allCorrect = checkResponses(answer.user_data);

          // Add this student into the response set
          $scope.allResponses.push( { respondentId: respondentId, type: RespondentType.USER, response: answer.user_data, correct: allCorrect } );
        }
      }

      function updateRespondents(answer) {
        var answerRespondentId = $scope.isUsingSmallGroups() ? answer.small_gid : answer.user_id;
        var currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;
        var respondentType = $scope.isUsingSmallGroups() ? RespondentType.GROUP : RespondentType.USER;

        // If this user is submitting real data then make sure they are in the list of users
        var respondents = $scope.respondents;
        if (answer.user_data && answer.user_data.length > 0 && ($scope.isTeacher || answerRespondentId === currentRespondentId)) {
          // See if they are already in the list
          var inList = false;
          respondents.forEach(function(respondent){
            if (respondent.id === answerRespondentId)
              inList = true;
          });

          // add to list if necessary
          if (!inList) {
            respondents.push({'id': answerRespondentId, 'type': respondentType });
          }
        }
        else if (!answer.user_data || !answer.user_data.length) {
          // if this is the teacher receiving this message then
          if ($scope.isTeacher) {
            // Remove this student from the list of students
            $scope.respondents = $scope.respondents.filter(function (respondent) {
              return respondent.id !== $scope.selectedRespondent;
            });
          }
          else {
            $scope.editing = true;  // The student should go directly into edit mode
          }
        }

        // Sort if this is groups
        if ($scope.isUsingSmallGroups()) {
          respondents.sort();
        }

        if ($scope.isUsingSmallGroups()) {
          $scope.portionResponded = (respondents.length) / context.clazz.smallGroups;
        }
        else if (context.clazz.students.length) {
          $scope.portionResponded = (respondents.length) / context.clazz.students.length;
        }
        else {
          $scope.portionResponded = 0;
        }
      }

      function processSharedState(result, firstTime) {
        var context = $scope.options.context;

        if (result instanceof Array && result.length) {
          result.forEach(function(answer){
            answer.user_data = JSONStringUtility.parse(answer.user_data);

            updateAnswer(answer);
            updateRespondents(answer);

            if (($scope.isUsingSmallGroups() && answer.small_gid == context.groupId) || (!$scope.isUsingSmallGroups() && answer.user_id == context.userId)) {
              $scope.responses = answer.user_data;
            }
          });
        }

        var cachedResponse = false;
        if ($scope.cached) {
          var cachedValue = $scope.cached({elementId: $scope.options.element.id});
          if (cachedValue) {
            $scope.responses = cachedValue;
            cachedResponse = true;
          }
        }

        // If the current student does NOT have an values then they can go directly to editing mode
        if (!$scope.isTeacher) {
          // Determine the current user/current group id
          var currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;

          var found = false;
          if (!cachedResponse) {
            $scope.allResponses.forEach(function(response) {
              // if this response matches the current user then
              if (response.respondentId == currentRespondentId) {
                // Make sure at least one of the responses contains a value
                found = response.response && response.response.length;
              }
            });
          }

          $scope.selectRespondent($scope.isUsingSmallGroups() ? context.groupId : context.userId);

          if (cachedResponse) {
            $scope.answered = $scope.responses.length > 0;
            $scope.status = [];
          }

          var isInactive = $scope.options.context.getViewingInactiveExperience();

          // If no record found then automatically start editing
          $scope.editing = (!found || cachedResponse || $scope.options.quiz) && !isInactive;
        }
        else if (ActiveExperience.currentExperience() && !ActiveExperience.currentExperience().hideStudentResponses) {
          // Just select the teacher by default
          $scope.selectRespondent($scope.getRespondent() ? $scope.getRespondent() : CorrectResponseId.CORRECTANSWERS);
        }

        if (firstTime) {
          // Notify the widget that were are done loading the data
          widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
        }
      }

      function loadAnswers(firstTime) {
        if (!element || !element.id) {
          return;
        }

        // Clear any existing responses as they will be reloaded
        $scope.respondents = [];
        $scope.allResponses = [];

        // if this is the teacher then add the set of correct answers for them
        if ($scope.isTeacher && (!$scope.options.context.getViewingInactiveExperience() ||
            ($scope.options.context.filteredUser && $scope.options.context.filteredUser.report === 1))) {
          // Add the correct responses to the teacher into the response set
          $scope.respondents.push({ id: CorrectResponseId.CORRECTANSWERS, type: RespondentType.USER, showCheckMark: true });
          var teacherResponses = $scope.question.segments.filter(function(label) {
            return label.correct;
          }).map(function(correct) {
            return correct.id;
          });
          $scope.allResponses.push( { respondentId: CorrectResponseId.CORRECTANSWERS, type: RespondentType.USER, response: teacherResponses, correct: 1 } );
        }

        var isInactive = $scope.options.context.getViewingInactiveExperience();

        ElementsRestService.getSharedState($scope.options.context.experienceId, $scope.options.element.id, $scope.options.context.groupName, isInactive,
          function(result) {
            processSharedState(result, firstTime);
          },
          function(error) {
            ElementsErrorService.error(error);
        });
      }

      $scope.selectHotSpot = function(label) {
        if (($scope.editing || !$scope.isTeacher) && !$scope.options.context.getViewingInactiveExperience()) {
          if (!$scope.multiSelect) {
            $scope.responses = [label.id];
          } else {
            if ($scope.responses.includes(label.id)) {
              $scope.responses = $scope.responses.filter(function(response) { return response !== label.id; });
            } else {
              if (!$scope.maxSelect || $scope.responses.length < $scope.maxSelect) {
                $scope.responses.push(label.id);
              }
            }
          }

          $scope.answered = $scope.responses.length > 0;

          if ($scope.selectionMade) {
            $scope.selectionMade({selected: $scope.responses.length > 0});
          }

          if ($scope.answered && $scope.changed) {
            $scope.changed({elementId: $scope.options.element.id, selection: $scope.responses});
          }
        }
      };

      $scope.$watch('savingElementId', function(newValue, oldValue) {
        // Submit the response selected in this element
        if (newValue && newValue.elementId === $scope.options.element.id){
          $scope.didSubmit(newValue);
        }
      }, true);

      $rootScope.$on('teacher-notes', function() {
        if ($scope.isTeacher) {
          if (ActiveExperience.currentExperience().hideStudentResponses) {
            $scope.selectRespondent(context.userId);
          } else {
            $scope.selectRespondent(CorrectResponseId.CORRECTANSWERS);
          }
        }
      });

      function checkResponses(selections) {
        var allCorrect = true;
        var someCorrect = false;

        // Iterate over the responses and see if each correctly matches the correct response
        $scope.question.segments.forEach(function(option, index) {
          // See if this matches the nth value in the source
          if ((option.correct && selections.indexOf(option.id) === -1) ||
              (!option.correct && selections.indexOf(option.id) !== -1)) {
            allCorrect = false;
          }
          else if (option.correct && selections.indexOf(option.id) !== -1) {
            someCorrect = true;
          }
        });

        return allCorrect ? 1 : (someCorrect ? -1 : 0);
      }

      function mapCorrectToIcon(correct) {
        if (correct === -1) return IconOverlays.MINUS;
        if (correct === 1) return IconOverlays.CHECK;
        return IconOverlays.CROSS;
      }

      $scope.correctOverlay = function(respondentId) {
        if (!$scope.isTeacher || !$scope.isAssessed) {
          return undefined;
        }

        // Search through all the responses
        for (var index = 0; index < $scope.allResponses.length; ++index) {
          // if this the same group
          if (respondentId === $scope.allResponses[index].respondentId) {
            return mapCorrectToIcon($scope.allResponses[index].correct);
          }
        }

        return IconOverlays.CROSS;
      };

      $scope.shouldShowResults = function () {
        if (!$scope.options || !$scope.options.context){
          return false;
        }

        if (!$scope.options.context.getShowStudentResponses()) {
          return false;
        }
        return true;
      };

      $scope.didSubmit = function(savingElement) {
        let groupId = $scope.options.context.getPostingGroupId($scope.isUsingSmallGroups());

        // show the correct alert if student facing feedback is enabled
        if ($scope.options.context.getStudentFacingFeedback() && $scope.isAssessed && !$scope.options.quiz) {
          $scope.assessmentStatus = checkResponses($scope.responses);
        }

        // Save the new userstate information to the server
        ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.options.context.userId, groupId,
                                          $scope.responses, function() {
          // Calls back to quiz letting it know the score has been saved
          if ($scope.scoreSaved) {
            $scope.scoreSaved({ finished: (savingElement && savingElement.finished)});
          }
          if ($scope.changed) {
            $scope.changed({elementId: $scope.options.element.id, selection: null});
          }
          loadAnswers(false);
        },
        function(error) {
          ElementsErrorService.error(error);
        });
      };

      $scope.getEditMenuItemsForUser = function(respondentId) {
        let editRespondentId = context.getSelectedRespondentId($scope.isUsingSmallGroups(), respondentId);
        var menuOptions =
        [
          {
            text: '<div class="xp-element-menu-edit">Edit</div>',
            click: 'requestEdit(' + editRespondentId +')'
          }
        ];

        if ($scope.editing) {
          menuOptions =
          [
            {
              text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
              click: 'cancelEdit(' + editRespondentId +')'
            }
          ];
        }

        return menuOptions;
      };

      $scope.requestEdit = function(respondentId)
      {
        // reset the assessment status flag to hide the alert if it is showing
        $scope.assessmentStatus = false;

        $scope.selectRespondent(respondentId);

        // Clear the status for when in student facing feedback
        $scope.status = [];

        // Editing mode
        $scope.editing = true;
      };

      $scope.cancelEdit = function(respondentId)
      {
        // Re-selects original data for this user
        selectRespondentData(respondentId);

        if ($scope.changed) {
          $scope.changed({elementId: $scope.options.element.id, selection: null});
        }

        // Not longer in editing mode
        $scope.editing = false;
      };

      $scope.wrapRespondent = function (respondent) {
        var wrappedRespondent = new $scope.Respondent(respondent.id);
        wrappedRespondent.getType = function() {
          return respondent.type;
        };

        wrappedRespondent.isSelected = function () {
          return respondent.id === $scope.selectedRespondent;
        };

        wrappedRespondent.isCheckMark = function() {
          return respondent.showCheckMark;
        };

        wrappedRespondent.select = function () {
          $scope.selectRespondent(respondent.id);
        };

        wrappedRespondent.getOverlay = function getOverlay() {
          if (!respondent.showCheckMark) {
            return $scope.correctOverlay(respondent.id);
          } else {
            return null;
          }
        };

        return wrappedRespondent;
      };
    }]);
})();
