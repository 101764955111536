'use strict';
angular.module('xp-element-word_cloud', ['angularWidget', 'client.services', 'd3', 'client.directives', 'ngAnimate', 'mgcrea.ngStrap', 'ngSanitize'])
	.controller('clientWordCloudElementCtrl',
		['$scope', 'widgetConfig', '$http', 'ElementsRestService', 'ElementsErrorService', 'JSONStringUtility',
		'ElementUtilities', 'd3', 'SHARE_MODE', 'GATE_MODE', 'ModalService', '$log', 'RespondentType', '$sce',
function ($scope, widgetConfig, $http, ElementsRestService, ElementsErrorService, JSONStringUtility,
ElementUtilities, d3, SHARE_MODE, GATE_MODE, ModalService, $log, RespondentType, $sce) {

	$scope.options = widgetConfig.getOptions($scope);
	$scope.instructions = {};
	$scope.gateMode = GATE_MODE.XPGateModeGated;
	$scope.fontSize = 30;
	$scope.maxFontSize = 60;
	$scope.form = {response: ''};
	$scope.respondents = [];
	$scope.hiliteId = 0;
	$scope.hilitedIds = [];
	$scope.userWords = [];
	$scope.selectedWord = "";
	$scope.wcwidth = 900;
	$scope.selectedRespondent = null;
	$scope.portionResponded = 0;
	$scope.isTeacher = false;
	$scope.showShareIndicator = false;
	$scope.editing = false;
	$scope.groupUser = [];

	$scope.tabularData = [];

	$scope.SHARE_MODE = SHARE_MODE;
	$scope.share = SHARE_MODE.GROUP;

	function parseElement()	{
		var element = $scope.options.element;
		element.config.attributes.forEach(function(attribute) {
			var name = attribute.name;
			var value = attribute.value;
			switch (name) {
				case "instructions" :
					$scope.instructions.question = $sce.trustAsHtml(value);
				break;
				case "instructions_image_url" :
					$scope.instructions.imageUrl = ElementUtilities.getElementURL(element, $scope.options.context.experienceId, value);
					break;
				case "share" :
					$scope.share = attribute.value;
				break;
				case "gate_mode" :
					if (value == "gated") {
						$scope.gateMode = GATE_MODE.XPGateModeGated;
					} else {
						$scope.gateMode = GATE_MODE.XPGateModeUngated;
					}
					break;
			}
		});
	}

	$scope.$watch('options', function() {
		var options = $scope.options;
		if (!options.element) {
			return;
		}

		var service = options.elementRealtimeService;
		var EVENTS = service.EVENTS;
		var context = options.context;

		service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
		$scope.$on('$destroy', function(){
			service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
		});

		service.on(EVENTS.XPExperienceUpdatedNotification, handleExperienceUpdatedNotification);
		$scope.$on('$destroy', function(){
			service.removeListener(EVENTS.XPExperienceUpdatedNotification, handleExperienceUpdatedNotification);
		});

		parseElement();

		$scope.isTeacher = context.userIsTeacher();
		$scope.showShareIndicator = context.userIsTeacher();
    $scope.selectedRespondent = getRespondentId();

		if ($scope.totalNumberOfStudents == -1) { // only for the first time (redundant but just in case)
				$scope.totalNumberOfStudents = context.clazz.students.length; // here the class must be updated already
		}

		$scope.wcwidth = options.context.showAnswersOnly ? 720 : 900;

		loadAnswers();
	}, true);

	function parseWords(words) {
		// Split these words at the commas
		var splitWords = words.split(",");

		// Trim any spaces of the end of each word
		var wordArray = [];
		splitWords.forEach(function(word){
			wordArray.push(word.trim());
		});

		return wordArray.filter(function(elem, pos) {
				return wordArray.indexOf(elem) == pos;
		});
	}

	$scope.onInputChanged = function() {
    if ($scope.changed) {
      $scope.changed({elementId: $scope.options.element.id, selection: $scope.form.response});
    }
	};

	$scope.isTeacherViewingInactiveExperience = function() {
		if ($scope.options.context !== undefined)
			return $scope.options.context.getViewingInactiveExperience() &&
					$scope.options.context.userIsTeacher() &&
					$scope.tabularData.length > 0 &&
					!$scope.filteredStudent &&
					$scope.options.studentId === undefined;
		else
			return false;
	};

	$scope.hasResponses = function() {
	  return respondentHasData($scope.selectedRespondent, $scope.respondents);
	};

	function getClassUserName(answer)
	{
		var name = answer.user_id;

		// is this a group or user
		var isGroup = answer.small_gid && answer.small_gid !== 0;
		if (isGroup)
			name = "Group " + answer.small_gid;
		else
		{
			// Search for the matching student name in the clazz
			$scope.options.context.clazz.students.forEach(function(student){
				if (student.uid === answer.user_id)
					name = student.displayName;
			});

			// See if this is the teacher
      $scope.options.context.clazz.teachers.forEach(function(teacher){
        if (teacher.uid === answer.user_id)
          name = teacher.displayName;
      });
		}

		return name;
	}

	function loadAnswers()
	{
		if (!$scope.options.element.id)
			return;

    var isInactive = $scope.options.context.getViewingInactiveExperience();

    ElementsRestService.getSharedState($scope.options.context.experienceId, $scope.options.element.id, $scope.options.context.groupName, isInactive,
		function(result) {
			var newWords = [];
			var newRespondents = [];
			var maxOccurrence;

			$scope.tabularData = [];
			$scope.myAnswer = null;

			if (result instanceof Array)
			{
				maxOccurrence = 1;
				$scope.filterAnswers(result).forEach(function(answer){
					// add each users answer into scope
					maxOccurrence = addUserResponse(answer, newWords, newRespondents, maxOccurrence);
				});
			}

			// if this is a teacher then make sure then are in the list
			if ($scope.isTeacher)
			{
				// Look for the teacher in the list
				var foundTeacher = false;
				for (var index = 0; index < newRespondents.length && !foundTeacher; ++index)
				{
					// if this is the teacher then don't need to add
					if (newRespondents[index] === $scope.options.context.userId)
						foundTeacher = true;
				}

				// if no teacher in the list then add
				if (!foundTeacher)
					newRespondents.splice(0, 0, $scope.options.context.userId);
			}

			$scope.userWords = newWords;
			$scope.userWords.fontIncrement = maxOccurrence > 1 ? ($scope.maxFontSize - $scope.fontSize) / (maxOccurrence - 1) : 0;
			$scope.respondents = newRespondents;

			// Notify the widget that were are done loading the data
			widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});

			// See if a value was saved in the un-submitted cache for this element
			var cachedResponse = false;
      if ($scope.cached) {
        var cachedValue = $scope.cached({elementId: $scope.options.element.id});
        if (cachedValue) {
          $scope.form.response = cachedValue;
          cachedResponse = true;
        }
      }

			if ($scope.myAnswer !== null && !cachedResponse) {
				$scope.form.response = $scope.myAnswer.user_data;
			}

			// check to see if they submitted any data.  If not, then put them into edit mode immediately
			$scope.editing = !$scope.options.context.isReview &&
			                  ($scope.myAnswer === null || $scope.myAnswer.user_data === undefined || cachedResponse);
		},
		function(error) {
			ElementsErrorService.error(error);
		});
	}

	function addUserResponse(answer, newWords, newRespondents, maxOccurrence)
	{
		var context = $scope.options.context;

		// If this is a filtered view
		if ($scope.options.studentId === undefined || $scope.options.studentId === answer.user_id)
		{
			// Get the respondent ID which is either the user or group if using small groups
			var respondentId = $scope.isUsingSmallGroups() && !$scope.options.context.getUserIsTeacher(answer.user_id) ? answer.small_gid : answer.user_id;

			// If a valid answer then add data
			if (answer.user_data)
			{
				// Keep track of the user who changed the value in this group
				if ($scope.isUsingSmallGroups())
					$scope.groupUser[answer.small_gid] = answer.user_id;

				// Parse the user data to break up the words if necessary
				var studentWords = parseWords(answer.user_data);

				// Add each word to the array
				studentWords.forEach(function(studentWord){
					var found = false;
					// See if this work already exists.
					newWords.forEach(function(existingWord){
						// if the word matches then just increment its count
						if (existingWord.word === studentWord.toLowerCase())
						{
							existingWord.count++;
							found = true;
							existingWord.respondents.push(respondentId);

							// Set max occurence if this exceeds the previous max
							if (existingWord.count > maxOccurrence)
								maxOccurrence = existingWord.count;
						}
					});

					// if the word does not yet exist then add it
					if (!found)
					{
						var userData = {};
						userData.respondents = [];
						userData.respondents.push(respondentId);
						userData.word = studentWord.toLowerCase();
						userData.count = 1;
						newWords.push(userData);
					}
				});

				// Add each respondent into its own structure
				var foundUser = newRespondents.some(function(respondent) {
					return (answer.small_gid && answer.small_gid !== 0 && answer.small_gid === respondent) || (answer.small_gid === 0 && respondent === answer.user_id);
				});

				if (!foundUser)
				{
					// if the current user is the teacher then make sure their own avatar is always at the beginning
					if ($scope.isTeacher && respondentId === context.clazz.teacher.uid)
						newRespondents.splice(0, 0, respondentId);
					else
						newRespondents.push(respondentId);

					var teacherAdjustment = newRespondents.indexOf(context.clazz.teacher.uid) === -1 ? 0 : 1;
					if ($scope.isUsingSmallGroups())
						$scope.portionResponded = (newRespondents.length - teacherAdjustment) / context.clazz.smallGroups;
					else if (context.clazz.students.length)
						$scope.portionResponded = (newRespondents.length - teacherAdjustment) / context.clazz.students.length;
					else
						$scope.portionResponded = 0;
				}

				// Store values for tabular display
				var tabularRow = {};
				tabularRow.respondentId = respondentId;
				tabularRow.Student = getClassUserName(answer);
        tabularRow.gid = answer.small_gid ? answer.small_gid : null;
        tabularRow.uid = !answer.small_gid ? answer.user_id : null;
				tabularRow.isScored = false;

				// Clean up the format of the user data
				var cloudResponses = answer.user_data.split(",");

				// Trim any extra spaces from beginning and ending of words
				var cleanResponses = cloudResponses.map(function(response) {
				  return response.trim();
				});

				// Now build up a string that correctly formats all values
				var formattedResponse = cleanResponses.join(", ");

				// this is now the value to display
				tabularRow.Response = formattedResponse;

				// Loop through tabular data looking for any data for this user
				var foundTabularData = false;
				for (var index = 0; index < $scope.tabularData.length && !foundTabularData; ++index)
				{
					// if this row is for the current user then replace it
					if ($scope.tabularData[index].respondentId === respondentId)
					{
						$scope.tabularData[index] = tabularRow;
						foundTabularData = true;
					}
				}

				if (!foundTabularData)
					$scope.tabularData.push(tabularRow);
			}
			else
			{
				// Remove this user since their data has been removed
				var respondentIndex = $scope.respondents.indexOf(respondentId);

				// if found then delete it
				if (respondentIndex > -1)
					$scope.respondents.splice(respondentIndex, 1);
			}

			if ((answer.small_gid && answer.small_gid !== 0 && answer.small_gid === context.groupId) || (answer.small_gid === 0 && context.userId === answer.user_id))
			{
				// Assign the current answer to current user
				$scope.myAnswer = answer;

				// Set editing flag if answer was deleted
				$scope.editing = $scope.myAnswer === null || $scope.myAnswer.user_data === undefined || $scope.myAnswer.user_data.length === 0;

				// Form should be defaulted to this value
				$scope.form.response = $scope.myAnswer.user_data;
			}
		}

		// Should we show the share indicator
		$scope.showShareIndicator = (context.userIsTeacher()) ||
		                            respondentHasData($scope.selectedRespondent, newRespondents) ||
		                            $scope.gateMode == GATE_MODE.XPGateModeUngated;

		return maxOccurrence;
	}

	function respondentData(respondentId) {
		var data = "";

		for (var index = 0; index < $scope.tabularData.length; ++index) {
			// if this row is for the current user then replace it
			if ($scope.tabularData[index].respondentId === respondentId)
				data = $scope.tabularData[index].Response;
		}
		return data;
	}

	function respondentHasData(respondentId, newRespondents)
	{
		var foundUser = newRespondents.some(function(respondent) {
			return respondent === respondentId;
		});

		return foundUser;
	}

	function removeUserResponse(answer)
	{
		var respondentId = (answer.small_gid && answer.small_gid !== 0) ? answer.small_gid : answer.user_id;

		// Loop through all words looking for values from this user
		for (var index = $scope.userWords.length - 1; index >= 0; --index)
		{
			var currentWord = $scope.userWords[index];

			// See if this user has an entry for this word
			for (var respondentIndex = currentWord.respondents.length - 1; respondentIndex >= 0; --respondentIndex)
			{
				// if the user id matches then
				if (currentWord.respondents[respondentIndex] == respondentId)
				{
					// remove from the array
					currentWord.respondents.splice(respondentIndex, 1);

					// decrement the word count
					currentWord.count--;
				}
			}

			// if we just removed the only instance of this word then delete the entire object
			if (currentWord.count <= 0)
				$scope.userWords.splice(index, 1);
		}
	}

	function updateResponse(answer, applyValues)
	{
		// Remove this users previous answeres
		removeUserResponse(answer);

		// Calculate the existing max same word count
		var maxOccurrence = 1;
		$scope.userWords.forEach(function(existingWord){
			if (existingWord.count > maxOccurrence)
				maxOccurrence = existingWord.count;
		});

		// Make a copy of the words
		var newWords = $scope.userWords.slice(0);

		// now re-add the new responses
		maxOccurrence = addUserResponse(answer, newWords, $scope.respondents, maxOccurrence);

		// May need to re-adjust the font size based on the new responses
		newWords.fontIncrement = maxOccurrence > 1 ? ($scope.maxFontSize - $scope.fontSize) / (maxOccurrence - 1) : 0;

		if (applyValues) {
  		$scope.$apply(function(){
  			$scope.userWords = newWords;
  		});
	  } else {
      $scope.userWords = newWords;
	  }
	}

	function stateChangedNotificationHandler(e) {
    var state = e.detail;
		if (state.record.element_id != $scope.options.element.id) return;

		$log.debug ("Received word-cloud state update: " + JSON.stringify(state));

		// The data should be a string
		var isString =  typeof(state.record.user_data) === 'string';
		if (!isString)
			state.record.user_data = "";

		// Convert record inton ans answer and update the response set
		var answer = {
			'user_id': parseInt(state.record.user_id, 10),
			'small_gid': parseInt(state.record.small_gid, 10),
			'user_data': state.record.user_data
		};

		updateResponse(answer, true);
	}

	function handleExperienceUpdatedNotification(e) {
    var experience = e.detail;
		$log.debug ("Received (word-cloud) experience  update: " + JSON.stringify(experience));

		ElementsRestService.getExperienceStudentsNumber($scope.options.context.experienceId,
				function(result) {
					$scope.totalNumberOfStudents = result;
				},
				function(error){
					ElementsErrorService.error(error);
				});
	}

	$scope.getWidth = function()
	{
		if ($scope.options.context.showAnswersOnly)
			return 600;
		else
			return 900;
	};

	$scope.shouldShowResults = function () {
		if (!$scope.options || !$scope.options.context){
			return false;
		}

		if (!$scope.options.context.getShowStudentResponses()) {
		  return false;
		}

		if (($scope.gateMode == GATE_MODE.XPGateModeUngated || $scope.options.context.userIsTeacher()) && $scope.userWords.length > 0) {
		  return true;
		} else {
		  return $scope.myAnswer !== null && $scope.myAnswer !== undefined;
		}
	};

	$scope.canSubmit = function() {
    if ($scope.options.context.isPreview && !$scope.options.context.isReview) {
      return false;
    }

    return $scope.form.response && $scope.form.response.length > 0;
	};

  function savePreviewData(userId, groupId, results, attachments) {
    let oldResults = "";
    let currentId = groupId ? groupId : userId;
    $scope.userWords.forEach(function(userWord) {
      if (userWord.respondents.includes(currentId)) {
        if (oldResults.length) {
          oldResults += ",";
        }
        oldResults += userWord.word;
      }
    });

    // Save the new user state
    ElementsRestService.saveAnalyticsUserState($scope.options.context.experienceId, $scope.options.element.id, userId, groupId, oldResults, results,
    function() {
      $scope.editing = false;
      if ($scope.changed) {
        $scope.changed({elementId: $scope.options.element.id, selection: null});
      }
      // Convert record inton ans answer and update the response set
      var answer = {
        'user_id': userId,
        'small_gid': groupId,
        'user_data': results
      };

      updateResponse(answer, false);
    },
    function(error) {
      ElementsErrorService.error(error);
    });
  }

	$scope.didSubmit = function() {
		// If this is either the teacher or we are using small groups then the group is the selected respondent
		var groupId = !$scope.respondentIsUser($scope.selectedRespondent) ? $scope.selectedRespondent : 0;

		// The user ID is the respondent if not using small groups or the current respondent is the teacher
		var userId = $scope.options.context.userId;
		if ($scope.respondentIsUser($scope.selectedRespondent))
			userId = $scope.selectedRespondent;
		else if ($scope.isTeacher && $scope.groupUser[$scope.selectedRespondent])
			userId = $scope.groupUser[$scope.selectedRespondent];

    // if this is a review then need to update existing records with new
    if ($scope.options.context.isReview) {
      savePreviewData(userId, groupId, $scope.form.response);
    } else {
  		// Save the new user state
  		ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, userId, groupId, $scope.form.response,
  		function() {
  			$scope.editing = false;
  	    if ($scope.changed) {
  	      $scope.changed({elementId: $scope.options.element.id, selection: null});
  	    }
  		},
  		function(error) {
  			ElementsErrorService.error(error);
  		});
    }
	};

	$scope.isUsingSmallGroups = function()
	{
		return SHARE_MODE.isUsingSmallGroups($scope.share);
	};

	$scope.respondentIsUser = function(respondentId)
	{
		return !$scope.isUsingSmallGroups() || $scope.options.context.getUserIsTeacher(respondentId);
	};

	$scope.respondentIsGroup = function(respondentId)
	{
		return !$scope.respondentIsUser(respondentId);
	};

	function getRespondentId()
	{
		if (!$scope.isUsingSmallGroups() || ($scope.isUsingSmallGroups() && $scope.options.context.userIsTeacher()))
			return $scope.options.context.userId;

		return $scope.options.context.groupId;
	}

	$scope.getRespondentDisplayId = function(respondentId)
	{
		if (!$scope.options.context)
			return respondentId;

		if ($scope.isUsingSmallGroups() && $scope.options.context.getUserIsTeacher(respondentId))
			return respondentId;

		return respondentId;
	};

	$scope.canEditForRespondent = function(respondentId) {
		if (!$scope.options.context)
			return false;

		if ($scope.options.context.isPreview && !$scope.options.context.isReview) {
		  return false;
		}

    if ($scope.options.context.getViewingInactiveExperience()) {
      return false;
    }

		return ($scope.options.context.userIsTeacher() && $scope.selectedRespondent) ||
				((($scope.options.context.userId == respondentId && !$scope.isUsingSmallGroups()) ||
				($scope.options.context.groupId == respondentId && $scope.isUsingSmallGroups())) &&
				$scope.hasResponses());
	};

	$scope.showWord = function(userId)
	{
		$scope.hiliteId = userId;
		$scope.hilitedIds = [];
		$scope.selectedWord = "";
		$scope.selectedRespondent = userId;
		$scope.editing = false;
	};

	$scope.onClick = function(element)
	{
		// user clicked on a word so select all users that submitted this word
		$scope.$apply(function () {
			// Which user does this word belong to
			$scope.hilitedIds = element.respondents;
			$scope.hiliteId = 0;
			$scope.selectedRespondent = $scope.hilitedIds.length > 0 ? $scope.hilitedIds[0] : 0;
			$scope.selectedWord = element.text;
				});
	};

	$scope.getEditMenuItemsForUser = function(respondentId)
	{
		var editOption = {
				text: '<div class="xp-element-menu-edit">Edit</div>',
				click: 'requestEdit("' + respondentId +'")'
			};

		if ($scope.editing)
		{
			editOption = {
					text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
					click: 'requestCancelEdit("' + respondentId +'")'
				};
		}

		var menuOptions;

		// A teacher can delete responses
		if ($scope.isTeacher && !$scope.options.context.isReview)
		{
			menuOptions =
			[
				editOption,
				{
					divider: true
				},
				{
					text: '<div class="xp-element-menu-delete">Delete</div>',
					click: 'requestDelete("' + respondentId +'")'
				}
			];
		}
		else
		{
			menuOptions =
				[
				editOption
				];
		}

    if ($scope.options.context.isReview) {
      menuOptions.push(
      {
        text: '<div class="xp-element-menu-edit">Approve</div>',
        click: 'approvePreviewRepsonses()'
      });
    }

		return menuOptions;
	};

	$scope.requestEdit = function(respondentId) {
		$scope.editing = true;
		$scope.form.response = respondentData(parseInt(respondentId, 10));
	};

  $scope.approvePreviewRepsonses = function() {
    ElementsRestService.approveAnalyticsUserState($scope.options.context.experienceId, $scope.options.element.id)
    .then(function(res) {
      if (res && res.status && res.status == "Approved") {
        ModalService.show({
          message: "Analytic data for this element is now approved.",
          backdrop: 'static',
          buttons: [
            {
              title: 'Ok',
              click: '$hide();'
            }
          ]
        });
      }
    });
  };

	$scope.requestCancelEdit = function(respondentId)
	{
		ModalService.show(
			{
				title: 'Cancel edit? You will lose all changes.',
				buttons:
					[
						{
							title: 'Yes',
							click: 'cancelEdit();$hide();'
						},
						{
							title: 'No',
							click: '$hide()'
						}
					],
				cancelEdit: function(){cancelEdit(respondentId);}
			}
		);
	};

	function cancelEdit(respondentId)
	{
		$scope.editing = false;
    if ($scope.changed) {
      $scope.changed({elementId: $scope.options.element.id, selection: null});
    }
	}

	$scope.requestDelete = function(respondentId)
	{
		ModalService.show(
			{
				title: 'Delete words?',
				buttons:
					[
						{
							title: 'Delete',
							click: 'deleteData(); $hide();'
						},
						{
							title: 'Cancel',
							click: '$hide()'
						}
					],
				deleteData: function(){deleteData(respondentId);}
			}
		);
	};

	function deleteData(respondentId)
	{
		// Clear out the data
		$scope.form.response = '';
		$scope.didSubmit();
	}

  $scope.wrapRespondent = function (respondent) {
    var id = $scope.getRespondentDisplayId(respondent);
    var wrappedRespondent = new $scope.Respondent(id);

    wrappedRespondent.getType = function() {
        if ($scope.respondentIsUser(respondent)) {
        return RespondentType.USER;
      }

      return RespondentType.GROUP;
    };

    wrappedRespondent.isSelected = function () {
      return ($scope.hiliteId == respondent || $scope.hilitedIds.indexOf(respondent) > -1);
    };

    wrappedRespondent.select = function () {
      $scope.showWord(respondent);
    };

    return wrappedRespondent;
  };

	}]);
